import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import Cookies from "js-cookie";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAC4S4JzJs77eytp0YqyYWFieTX-rbDB5Y",
  authDomain: "ceyone-8d521.firebaseapp.com",
  projectId: "ceyone-8d521",
  storageBucket: "ceyone-8d521.appspot.com",
  messagingSenderId: "147012558799",
  appId: "1:147012558799:web:407424afcb8c6e63d33cba",
  measurementId: "G-L5DRCQLRHX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// Function to request permission and get the device token
export const requestPermission = async () => {
  const permission = await Notification.requestPermission();
 
  if (permission === "granted") {
    const existingToken = localStorage.getItem("device_token");
    if (!existingToken) {
      const token = await getToken(messaging, {
        vapidKey: "BLprujbT_4YvsJfQ5TkfNyE0jlpk_VLGgl8qpKw5a8K1Y_QqaOTMYLA0qbJ78gyDO45AJUsPI52RWmdzV80SK8k",
      });
      Cookies.set("device_token", token);
      console.log("FCM Token generated:", token);
    } else {
      console.log("FCM Token already exists:", existingToken);
    }
  } else if (permission === "denied") {
    alert("Notifications are denied.");
  }

};

// Function to set up message handling
export const setupMessageListener = () => {
  onMessage(messaging, (payload) => {
    console.log("Message received:", payload);
    // Handle the incoming message here
    // You can show a notification or update your app's UI
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
      icon: "/firebase-logo.png", // Change to your icon
    };

    // Show notification using the Notification API
    
      new Notification(notificationTitle, notificationOptions);
      checkTabDetection(); // Use a custom name instead of 'name'
    
  });
};

// Function to detect tab visibility and redirect
function checkTabDetection() {
  document.addEventListener('visibilitychange', () => {
    if (!document.hidden) {
      window.location.href = `http://localhost:3000/dashboard`;
    }
  });
}

// Call the requestNotificationPermission and setupMessageListener functions



