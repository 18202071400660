// import React from 'react';
// import { Navigate } from 'react-router-dom';
// import Cookies from "js-cookie";

// const PersistedRoute = ({ element }) => {
//     const isAuthenticated = true; // Replace with actual authentication check
//     if (isAuthenticated) {
//         // Check for a stored route
//         const previousRoute = Cookies.get('previousRoute');
//         if (previousRoute) {
//             Cookies.remove('previousRoute');
//             return <Navigate to={previousRoute} />;
//         }
//         return element;
//     } else {
//         // Redirect to login page or other
//         return <Navigate to="/" />;
//     }
// };
// export default PersistedRoute;
import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Cookies from "js-cookie";

const PersistedRoute = ({ element }) => {
    const location = useLocation();

    useEffect(() => {
        // Store the current path in cookies, except for '/', '/logout', and '/forgotpass'
        if (location.pathname !== '/' && location.pathname !== '/logout' && location.pathname !== '/forgotpass') {
            Cookies.set('previousRoute', location.pathname, { expires: 7 });
        }
    }, [location.pathname]);
    let pageId;

    if (location.pathname === "/dashboard") {
        pageId = 1;

    } else if (location.pathname === "/useradd") {
        pageId = 2;

    } else if (location.pathname === "/useredit/:id") {
        pageId = 3;

    } else if (location.pathname === "/userlist") {
        pageId = 4;

    } else if (location.pathname === "/customermanagement") {
        pageId = 6;
    }
    else if (location.pathname === "/addcustomer") {
        pageId = 5;
    } else if (location.pathname === "/editcustomer/:id") {
        pageId = 7;
    } else if (location.pathname === "/vehicleassign") {
        pageId = 8;
    } else if (location.pathname === "/vehicleassign/:id") {
        pageId = 9;
    } else if (location.pathname === "/Viewvehicle") {
        pageId = 10;
    } else if (location.pathname === "/myprofile") {
        pageId = 11;
    }


    const isAuthenticated = true; // Replace with actual authentication check
    if (isAuthenticated) {
        return element;
    } else {

        return <Navigate to="/notaccess" />;
    }
};

export default PersistedRoute;























