import './App.css';
import '../src/Components/Styles/media.css'
import React, { useEffect } from 'react';

import { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Logout from './Components/Pages/Logout';
import ProtectedRoute from './Components/Security/Security';
import PersistedRoute from './Components/Pages/PersistedRoute';
import Cookies from "js-cookie";
import { requestPermission, setupMessageListener } from './firebase';
import DonotAccess from './Components/Pages/DonotAccess';
import AccessControlRoute from './Components/AccessControll/AccessControlRoute';

// Lazy load components
const Login = lazy(() => import('./Components/Pages/Login'));
const Layout = lazy(() => import('./Components/Layout/Layout'));
const Dashboard = lazy(() => import('./Components/Pages/Dashboard'));
const UserAdd = lazy(() => import('./Components/Pages/UserAdd'));
const UserList = lazy(() => import('./Components/Pages/UserList'));
const MyProfile = lazy(() => import('./Components/Pages/MyProfile'));
const Customer = lazy(() => import('./Components/Pages/Customer'));
const Addcustomer = lazy(() => import('./Components/Pages/Addcustomer'));
const Forgotpass = lazy(() => import('./Components/Pages/Forgotpass'));
const Otp = lazy(() => import('./Components/Pages/Otp'))
const Resetpass = lazy(() => import('./Components/Pages/Resetpass'))
const VehicleRegister = lazy(() => import('./Components/Pages/VehicleRegister'));
const VehicleRegisterList = lazy(() => import('./Components/Pages/VehicleRegisterList'));
const VehicleModel = lazy(() => import('./Components/Pages/VehicleModel'));
const VehicleModelList = lazy(() => import('./Components/Pages/VehicleModelList'));
const VehicleColor = lazy(() => import('./Components/Pages/VehicleColor'));
const VehicleColorList = lazy(() => import('./Components/Pages/VehicleColorList'));
const Maintenancemode = lazy(() => import('./Components/Pages/Maintenancemode'));
const Maintenence = lazy(() => import('./Components/Pages/Maintenence'));
const NotFound = lazy(() => import('./Components/Pages/Not_found'));
const Addmaintenence = lazy(() => import('./Components/Pages/Addmaintenence'));
const Vehicleassign = lazy(() => import('./Components/Pages/Vehicleassign'));
const Viewvehicle = lazy(() => import('./Components/Pages/Viewvehicle'));
const RoleList = lazy(() => import('./Components/Pages/RoleList'));
const AddRole = lazy(() => import('./Components/Pages/AddRole'));
const Customer_approve = lazy(() => import('./Components/Pages/CustomerApprove'));
const Customer_approve_detail = lazy(() => import('./Components/Pages/CustomerApproveDetail'));
const UserReports = lazy(() => import('./Components/Reports/UserReports'));
const SubscriptionReports = lazy(() => import('./Components/Reports/SubscriptionReports'));
const CustomerReports = lazy(() => import('./Components/Reports/CustomerReports'));

function AppContent() {
  const location = useLocation();
  function getPageTitle(path) {
    if (path.includes('/useredit/')) {
      return 'User Edit';
    }
    if (path.includes('/batteryedit')) {
      return 'Battery Edit';
    }
    if (path.includes('/dealeredit')) {
      return 'Dealer Edit';
    }
    if (path.includes('/editcustomer')) {
      return 'Edit Customer';
    }
    if (path.includes('/customerapprovedetails')) {
      return 'Customer Request Details';
    }

    if (path.includes('/addcustomer')) {
      return 'Add Customer';
    }



    if (path.includes('/vehicleassign')) {
      return ' Vehicle Assign';
    }






    switch (path) {
      case '/dashboard':
        return 'Dashboard';

      // user
      case '/useradd':
        return 'User Add';
      case '/userlist':
        return 'User List';
      case '/useredit/:id':
        return 'User Edit';

      case '/customermanagement':
        return 'Customer List';
      case '/addcustomer':
        return 'Custmer Add';
      case '/station':
        return 'Station List';
      case '/editcustomer':
        return 'Edit Customer';



      // myprofile
      case '/myprofile':
        return 'My Profile';

      // battery


      // vehicle register
      case '/vehicleregister':
        return 'Vehicle Register';
      case '/vehicleregisterlist':
        return 'Vehicle Register list'
      case '/rolelist':
        return 'Role list';
      case '/roleedit':
        return 'Role edit'

      // vehicle model
      case '/vehiclemodel':
        return 'Vehicle Model';
      case '/vehiclemodellist':
        return 'Vehicle Model List'
      case '/customerapprovelist':
        return 'Customer Request List'
      case '/customerapprovedetails':
        return 'Customer Request Details'

      // vehicle color
      case '/vehiclecolor':
        return 'Vehicle Color'
      case '/vehiclecolorlist':
        return 'Vehicle Color List'
      // Reports
      case '/subscriptionreports':
        return 'Subscription Reports'
      case '/customerreports':
        return 'Customer Reports'
      case '/dealerreports':
        return 'Dealer Reports'

      default:
        return 'Users';
    }
  };
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
    // Listen for messages from the service worker
    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data && event.data.type === 'NOTIFICATION_RECEIVED') {
        // Handle notification data or URL change
        console.log('Notification received:', event.data);
        // Set up visibility change listener
        document.addEventListener('visibilitychange', () => {
          if (!document.hidden) {
            // Perform actions when the user returns to the tab
            window.location.href = event.data.url;
          }
        });
      }
    });
  }

  useEffect(() => {
    requestPermission();

    // Listen for foreground messages
    setupMessageListener();
  }, []);





  return (
    <Suspense fallback={<div className="loader">Loading...</div>}>
      <Routes>
        <Route path="/" element={<Login />} />
        {/*forgatepass*/}
        <Route path="forgotpass" element={<Forgotpass />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/resetpass" element={<Resetpass />} />
        <Route path="Maintenancemode" element={<Maintenancemode />} />
        {/* NotFound */}
        <Route path="*" element={<NotFound />} />
        <Route element={<ProtectedRoute />}>
          <Route element={<Layout pageTitle={getPageTitle(location.pathname)} />}>

            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            <Route path="/dashboard" element={<AccessControlRoute permission="IsView" pageid={1} element={<Dashboard />} />} />
            {/* user */}
            {/* <Route path="/useradd" element={<PersistedRoute element={<UserAdd />} />} /> */}
            <Route
              path="/useradd"
              element={<AccessControlRoute permission="IsAdd" pageid={17} element={<UserAdd />} />}
            />
            <Route
              path="/useredit/:id"
              element={<AccessControlRoute permission="IsUpdate" pageid={17} element={<UserAdd />} />}
            />

            <Route path="/userlist" element={<PersistedRoute element={<UserList />} />} />
            <Route
              path="/addcustomer"
              element={<AccessControlRoute permission="IsAdd" pageid={5} element={<Addcustomer />} />}
            />
            <Route
              path="/editcustomer/:id"
              element={<AccessControlRoute permission="IsUpdate" pageid={5} element={<Addcustomer />} />}
            />
            <Route path='/customermanagement' element={<PersistedRoute element={<Customer />} />} />
            <Route
              path="/vehicleassign/"
              element={<AccessControlRoute permission="IsAdd" pageid={6} element={<Vehicleassign />} />}
            />
            <Route
              path="/Viewvehicle"
              element={<AccessControlRoute permission="IsView" pageid={7} element={<Viewvehicle />} />}
            />
            <Route path='/rolelist' element={<PersistedRoute element={<RoleList />} />} />
            <Route
              path="/roleedit"
              element={<AccessControlRoute permission="IsAdd" pageid={19} element={<AddRole />} />}
            />
            <Route
              path="/roleedit/:id"
              element={<AccessControlRoute permission="IsUpdate" pageid={19} element={<AddRole />} />}
            />

            <Route
              path="/myprofile"
              element={<AccessControlRoute permission="IsUpdate" pageid={13} element={<MyProfile />} />}
            />
            {/* battery */}

            <Route path='/logout' element={<PersistedRoute element={<Logout />} />} />




            {/* vehicle Register */}
            <Route path='/vehicleregister' element={<PersistedRoute element={<VehicleRegister />} />} />
            <Route path='/vehicleregisteredit/:id' element={<PersistedRoute element={<VehicleRegister />} />} />
            <Route path='/vehicleregisteredit' element={<PersistedRoute element={<VehicleRegister />} />} />
            <Route path='/vehicleregisterlist' element={<PersistedRoute element={<VehicleRegisterList />} />} />

            {/* Vehicle Model */}
            <Route path='/vehiclemodel' element={<PersistedRoute element={<VehicleModel />} />} />
            <Route path='/vehiclemodeledit/:id' element={<PersistedRoute element={<VehicleModel />} />} />
            <Route path='/vehiclemodellist' element={<PersistedRoute element={<VehicleModelList />} />} />
            <Route path='/Viewvehicle' element={<PersistedRoute element={<Viewvehicle />} />} />

            {/* Vehicle Color */}
            <Route path='/vehiclecoloredit/:id' element={<PersistedRoute element={<VehicleColor />} />} />
            <Route path='/vehiclecolor' element={<PersistedRoute element={<VehicleColor />} />} />
            <Route path='/vehiclecolorlist' element={<PersistedRoute element={<VehicleColorList />} />} />
            {/* maintenence */}
            <Route path='/maintenence' element={<PersistedRoute element={<Maintenence />} />} />
            <Route path='/Addmaintenence' element={<PersistedRoute element={<Addmaintenence />} />} />
            <Route path='/Editmaintenence/:id' element={<PersistedRoute element={<Addmaintenence />} />} />
            {/* batteries */}
            <Route path='/vehicleassign/:CustomerID' element={<PersistedRoute element={<Vehicleassign />} />} />
            <Route path="/userreports" element={<AccessControlRoute permission="IsView" pageid={1} element={<UserReports />} />} />
            <Route path="/subscriptionreports" element={<AccessControlRoute permission="IsView" pageid={1} element={<SubscriptionReports />} />} />
            <Route path="/customerreports" element={<AccessControlRoute permission="IsView" pageid={1} element={<CustomerReports />} />} />


            <Route path='/customerapprovelist' element={<PersistedRoute element={<Customer_approve />} />} />
            <Route path='/customerapprovedetails/:id' element={<PersistedRoute element={<Customer_approve_detail />} />} />

            <Route path='/notaccess' element={<DonotAccess />} />



          </Route>
        </Route>
      </Routes>


    </Suspense>
  );
}

function App() {
  return (
    <div className="App">

      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
    </div>
  );
}

export default App;