import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Show confirmation dialog
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will be logged out of your account.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2F6F92',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, logout!',
        }).then((result) => {
            if (result.isConfirmed) {
                // Clear cookies and local storage
                Cookies.remove('Username');
                Cookies.remove('Password');
                Cookies.remove('previousRoute');
                Cookies.remove('token');
                localStorage.clear();

                // Show success toast
                toast.success('You have successfully logged out!', {
                    position: "top-right",
                    autoClose: 3000,
                });

                // Redirect to login page
                navigate('/');
            } else {
                // Optional: Navigate back to the previous route or handle cancellation
                const previousRoute = Cookies.get('previousRoute') || '/';
                navigate(previousRoute);
            }
        });
    }, [navigate]);

    return null;
};

export default Logout;
