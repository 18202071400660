import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Maintenancemode from "../Pages/Maintenancemode";

const Sidebar = () => {
  const [subreportVisible, setSubreportVisible] = useState(false);
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const location = useLocation();

  const toggleReportMenu = () => {
    setSubreportVisible(!subreportVisible);
  };

  useEffect(() => {
    const savedState = localStorage.getItem("isMaintenanceMode");
    if (savedState) {
      setIsMaintenanceMode(JSON.parse(savedState));
    }
  }, []);

  // const getLinkClass = (path) => {
  //     // Check if the current location starts with the path
  //     return location.pathname.startsWith(path) ? 'active-menu-item' : '';
  // };

  const getLinkClass = (path) => {
    const pathname = location.pathname;

    // Check if the path matches the current location
    if (pathname.startsWith(path)) {
      return "active-menu-item";
    }

    // Specific checks for paths that might overlap with others

    if (
      path === "/customermanagement" &&
      (pathname.includes("/addcustomer") ||
        pathname.includes("/editcustomer") ||
        pathname.includes("/Viewvehicle") ||
        pathname.includes("/vehicleassign"))
    ) {
      return "active-menu-item";
    }

    if (
      path === "/userlist" &&
      (pathname.includes("/useredit") || pathname.includes("/useradd"))
    ) {
      return "active-menu-item";
    }

    if (path === "/rolelist" && pathname.includes("/roleedit")) {
      return "active-menu-item";
    }
    if (
      path === "/customerapprovelist" &&
      pathname.includes("/customerapprovedetails")
    ) {
      return "active-menu-item";
    }

    if (
      path === "/vehicleregisterlist" &&
      (pathname.includes("/vehicleregister") ||
        pathname.includes("/vehicleregisteredit"))
    ) {
      return "active-menu-item";
    }

    if (
      path === "/vehiclemodellist" &&
      (pathname.includes("/vehiclemodeledit") ||
        pathname.includes("/vehiclemodel"))
    ) {
      return "active-menu-item";
    }

    if (
      path === "/vehiclecolorlist" &&
      (pathname.includes("/vehiclecoloredit") ||
        pathname.includes("/vehiclecolor"))
    ) {
      return "active-menu-item";
    }
    if (path === "/userreports") {
      return "active-menu-item";
    }
    if (path === "/subscriptionreports") {
      return "active-menu-item";
    }
    if (path === "/customerreports") {
      return "active-menu-item";
    }

    return "";
  };

  return (
    <>
      <aside className="sidebar text-white text-start bg-gray position-fixed col-2">
        <ul className="list-unstyled">
          <h4 className="activity" style={{ color: "#2f6f92" }}>
            My activity
          </h4>
          <li className={`custom-list-item ${getLinkClass("/dashboard")}`}>
            <Link to="/dashboard" className="text-decoration-none custom-link">
              <span className="icon-cont">
                <i class="fa-solid fa-house"></i>
              </span>
              <span className="side-title">Dashboard</span>
            </Link>
          </li>

          <li
            className={`custom-list-item ${
              getLinkClass("/userlist") ||
              location.pathname.includes("/useradd", "/useredit")
                ? "active-menu-item"
                : ""
            }`}
          >
            <Link to="/userlist" className="text-decoration-none custom-link">
              <span className="icon-cont">
                <i className="fa-solid fa-user side-icn" />
              </span>
              <span className="side-title">Users</span>
            </Link>
          </li>

          <li
            className={`custom-list-item ${
              getLinkClass("/customermanagement") ||
              location.pathname.includes("/addcustomer", "/editcustomer")
                ? "active-menu-item"
                : ""
            }`}
          >
            <Link
              to="/customermanagement"
              className="text-decoration-none custom-link"
            >
              <span className="icon-cont">
                {" "}
                <i className="fa-regular fa-address-card side-icn"></i>
              </span>{" "}
              <span className="side-title ">Customer</span>
            </Link>
          </li>
          <li
            className={`custom-list-item ${
              getLinkClass("/rolelist") ? "active-menu-item" : ""
            }`}
          >
            <Link to="/rolelist" className="text-decoration-none custom-link">
              <span className="icon-cont">
                <i className="fa-solid fa-user side-icn" />
              </span>{" "}
              <span className="side-title ">Role</span>
            </Link>
          </li>
          <li
            className={`custom-list-item ${
              getLinkClass("/customerapprovelist") ? "active-menu-item" : ""
            }`}
          >
            <Link
              to="/customerapprovelist"
              className="text-decoration-none custom-link"
            >
              <span className="icon-cont">
                <i className=" fa-regular fa-address-card side-icn" />
              </span>{" "}
              <span className="side-title ">Request</span>
            </Link>
          </li>

          {/* <li className={`custom-list-item ${(submenuVisible || location.pathname.includes('/vehicleregisterlist') || location.pathname.includes('/vehicleregister') || location.pathname.includes('/vehiclemodel') || location.pathname.includes('/vehiclecolor') || location.pathname.includes('/vehiclemodellist') || location.pathname.includes('/vehiclecolorlist') || location.pathname.includes('/vehiclecoloredit')) ? 'active-menu-item submenu-open' : ''}`}>
                        <Link className="text-decoration-none custom-link" onClick={toggleSubmenu}>
                            <span className='icon-cont'>
                                <i className="fa-solid fa-motorcycle"></i></span>
                            <span className='side-title'>Vehicle</span>
                        </Link>
                        {(submenuVisible || location.pathname.includes('/vehicleregisterlist') || location.pathname.includes('/vehiclemodellist') || location.pathname.includes('/vehiclecolorlist')) && (
                            <ul className="submenu">
                                <li className={`submenu-item ${getLinkClass('/vehicleregisterlist') || location.pathname.includes('/vehicleregister') ? 'active-menu-item' : ''}`}>
                                    <Link to="/vehicleregisterlist" className="text-decoration-none text-white ">Vehicle Register</Link>
                                </li>
                                <li className={`submenu-item ${getLinkClass('/vehiclemodellist') || location.pathname.includes('/vehiclemodeledit') || location.pathname.includes('/vehiclemodel') ? 'active-menu-item' : ''}`}>
                                    <Link to="/vehiclemodellist" className={`text-decoration-none text-white ${getLinkClass('/vehiclemodellist')}`}>Vehicle Model</Link>
                                </li>
                                <li className={`submenu-item ${getLinkClass('/vehiclecolorlist') || location.pathname.includes('/vehiclecoloredit') || location.pathname.includes('/vehiclecolor') ? 'active-menu-item' : ''}`}>
                                    <Link to="/vehiclecolorlist" className={`text-decoration-none text-white ${getLinkClass('/vehiclecolorlist')}`}>Vehicle Color</Link>
                                </li>
                            </ul>
                        )}
                    </li>
                    <li className={`custom-list-item ${getLinkClass('/maintenence')}`} style={{ marginTop: '5px' }}>
                        <Link to="/maintenence" className="text-decoration-none custom-link">

                            <span className='icon-cont'>
                                <i className="fa-solid fa-tools side-icn" /></span>

                            <span className='side-title'>CMS</span>

                        </Link>
                    </li> */}
          {/* Reports */}
          <li
            className={`custom-list-item ${
              subreportVisible || location.pathname.includes("report")
                ? "active-menu-item submenu-open"
                : ""
            }`}
          >
            <Link
              className="text-decoration-none custom-link"
              onClick={toggleReportMenu}
            >
              <span className="icon-cont">
                <i className="fa-file-excel fa-solid"></i>
              </span>
              <span className="side-title">Reports</span>
            </Link>
            {subreportVisible && (
              <ul className="submenu">
                <li
                  className={`submenu-item ${
                    location.pathname === "/userreports"
                      ? "active-submenu-item"
                      : ""
                  }`}
                >
                  <Link
                    to="/userreports"
                    className="text-decoration-none text-white font-text"
                  >
                    User
                  </Link>
                </li>

                <li
                  className={`submenu-item ${
                    location.pathname === "/subscriptionreports"
                      ? "active-submenu-item"
                      : ""
                  }`}
                >
                  <Link
                    to="/subscriptionreports"
                    className="text-decoration-none text-white font-text"
                  >
                    Subscription
                  </Link>
                </li>
                <li
                  className={`submenu-item ${
                    location.pathname === "/customerreports"
                      ? "active-submenu-item"
                      : ""
                  }`}
                >
                  <Link
                    to="/customerreports"
                    className="text-decoration-none text-white font-text"
                  >
                    Customers
                  </Link>
                </li>
              </ul>
            )}
          </li>

          <li className={`custom-list-item ${getLinkClass("/logout")}`}>
            <Link to="/logout" className="text-decoration-none custom-link">
              <span className="icon-cont">
                <i className="fa-solid fa-arrow-right-from-bracket me-3 side-icn" />
              </span>
              <span className="side-title">Log Out</span>
            </Link>
          </li>
        </ul>
      </aside>
      {isMaintenanceMode && <Maintenancemode />}
    </>
  );
};
export default Sidebar;
