import React, { createContext, useState, useContext } from "react";

const DealerIDContext = createContext();

export const DealerIDProvider = ({ children }) => {
    const [DealerID, setDealerID] = useState(null);

    return (
        <DealerIDContext.Provider value={{ DealerID, setDealerID }}>
            {children}
        </DealerIDContext.Provider>
    );
};

export const useDealerID = () => useContext(DealerIDContext);
